import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

import { useCustomer } from './CustomerContext';

const ReviewContext = createContext();

export const ReviewProvider = ({ children }) => {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { customer } = useCustomer();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchReviews = async (productId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${apiBaseUrl}/products/fetch-reviews/?product_id=${productId}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch reviews');
      }
      const data = await response.json();
      setReviews(data);
      return data;
    } catch (error) {
      setError(error.message);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const submitReview = async (productId, reviewData) => {
    if (!customer) {
      setError('You must be logged in to submit a review.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiBaseUrl}/products/submit-review/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_id: productId,
          customer_id: customer.id,
          ...reviewData,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit review');
      }

      const data = await response.json();
      setReviews((prevReviews) => [...prevReviews, data]);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ReviewContext.Provider
      value={{ reviews, fetchReviews, submitReview, error, loading }}
    >
      {children}
    </ReviewContext.Provider>
  );
};

export const useReviews = () => useContext(ReviewContext);

ReviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};