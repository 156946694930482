import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useMemo } from 'react';

import { useCustomer } from './CustomerContext';

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { customer } = useCustomer();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchOrders = async () => {
    if (!customer) {
      setError('You must be logged in to fetch orders.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${apiBaseUrl}/products/fetch-orders/${customer.id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 404) {
        setOrders([]);
      } else if (!response.ok) {
        throw new Error('Network response was not ok');
      } else {
        const data = await response.json();
        setOrders(data);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const submitOrder = async () => {
    if (!customer) {
      setError('You must be logged in to submit an order.');
      return;
    }

    const orderData = {
      customer_id: customer.id,
    };

    try {
      const response = await fetch(`${apiBaseUrl}/products/submit-order/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit order');
      }

      const data = await response.json();
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  const refreshOrders = async () => {
    await fetchOrders();
  };

  const clearError = () => setError(null);

  const contextValue = useMemo(
    () => ({
      orders,
      loading,
      error,
      fetchOrders,
      submitOrder,
      refreshOrders,
      clearError,
    }),
    [orders, loading, error]
  );

  return (
    <OrderContext.Provider value={contextValue}>{children}</OrderContext.Provider>
  );
};

OrderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useOrders = () => {
  return useContext(OrderContext);
};
