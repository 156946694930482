import React from 'react';
import StarRating from '../components/StarRating';

const ReviewsProgressBar = ({ rating, count, count_list }) => {

  return (
    <>
      {[5, 4, 3, 2, 1].map((i) => (
        <div key={i} className="flex items-center w-full gap-4 md:justify-end mb-2">
          <StarRating rating={i} />
          <div className="progress-bar">
            <div
              className="progress-bar-fill"
              style={{
                width: `${(count_list[i - 1] / count) * 100}%`,
              }}
            ></div>
          </div>
          <div className="review-count">{count_list[i - 1]}</div>
        </div>
      ))}
    </>
  );
};

export default ReviewsProgressBar;
