import PropTypes from 'prop-types'; // Import PropTypes for props validation
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useProducts } from '../context/ProductContext'; // Import useProducts hook

import AddToCartButton from './AddToCartButton';
import '../css/ProductCard.css';
import StarRating from './StarRating';
import QuantityHandling from './QuantityHandling';

const ProductCard = ({ productId }) => {
  const { getProductById, generateHash } = useProducts();
  const product = getProductById(productId);
  const navigate = useNavigate();
  if (!product) {
    return <div>Product not found</div>;
  }

  const handleCardClick = () => {
    navigate(`/product/${product.id}`);
  };

  const hash = generateHash(product.id, product.variant_id);

  return (
    <div
      className="product-card m-2 lg:m-0 basis-[calc(50%-1.3rem)] lg:basis-[calc(25%-1rem)] border-[1px] rounded-xl relative cursor-pointer"
      onClick={handleCardClick}
    >
      <img
        src={`${process.env.REACT_APP_API_MEDIA_URL}${product.image}`}
        className="product-card-image w-full h-[155px] md:h-[315px] lg:h-[350px] rounded-t-xl"
        alt="Product image"
      />
      <div className="product-card-details p-2 md:p-4">
        <h2 className="product-card-name cursor-pointer text-tiny md:text-base text-black font-medium mb-2 truncate overflow-hidden whitespace-nowrap max-w-[150px] md:max-w-full">
          {product.name}
        </h2>
        {/* <p className="product-card-description">{product.description}</p> */}
        <div className="flex md:items-center flex-col md:flex-row">
          <p className="product-card-price font-semibold text-black text-xs md:text-base">
            Rs. {product.price}
          </p>
          <p className="product-card-price font-semibold text-[#5C5C5E] text-xs md:mx-2">
            M.R.P:{' '}
            <span className="line-through">Rs.{product.mrp}</span>
          </p>
          <p className="product-card-price font-medium text-white text-[10px] md:text-xs absolute top-4 right-2 md:right-6 bg-secondary rounded-lg px-2 md:px-4 py-1 md:py-2">
            {Math.floor(product.discount)}% OFF
          </p>
        </div>
        <div className="product-card-weight-reviews my-2">
          <p className="product-card-weight text-[#5C5C5E] text-xs">
            {product.weight}kg
          </p>
          <div className="product-card-ratings-reviews">
            <StarRating rating={product.rating} />
            {/* <div className="product-card-reviews">{product.review_count}</div> */}
          </div>
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex flex-row lg:flex-col items-center lg:items-start gap-1 lg:gap-0"
        >
          <QuantityHandling hash={hash} />
          <AddToCartButton
            className="product-card-add-to-cart-button"
            hash={hash}
          />
        </div>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default ProductCard;
