import PropTypes from 'prop-types';
import React from 'react';

import { useCart } from '../context/CartContext';

import QuantityHandling from './QuantityHandling';
import '../css/AddToCartButton.css';

const AddToCartButton = ({ hash }) => {
  const { setCartVisible, addToCart, isInCart } = useCart();

  const handleClick = () => {
    if (isInCart(hash)) {
      setCartVisible(true);
    } else {
      addToCart(hash);
    }

    // Remove hover effect after click
    const button = document.querySelector('.add-to-cart-button');
    if (button) {
      button.classList.remove('hover:bg-transparent', 'hover:text-secondary');
    }
  };

  return (
    <button
      className={`add-to-cart-button text-secondary text-xs md:text-base border-[1px] border-secondary rounded-md w-full h-auto py-2 lg:py-3 ${
        isInCart(hash)
          ? 'bg-secondary text-white lg:hover:bg-transparent lg:hover:text-secondary'
          : 'bg-transparent text-secondary lg:hover:bg-secondary lg:hover:text-white'
      }`}
      onClick={handleClick}
    >
      {isInCart(hash) ? 'Go to cart' : '+ Add to cart'}
    </button>
  );
};

AddToCartButton.propTypes = {
  hash: PropTypes.number.isRequired,
};

export default AddToCartButton;
