import React, { useState, useEffect } from 'react';
import {
  FaSearch,
  FaShoppingCart,
  FaUser,
  FaBox,
  FaSignOutAlt,
  FaBars, // Add FaBars for the menu icon
} from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import '../css/Navbar.css';
import { useAuth } from '../context/AuthContext';
import { useCart } from '../context/CartContext';
import { useCustomer } from '../context/CustomerContext';
import { useProducts } from '../context/ProductContext';
import logo from '../img/logo.png';
import searchIcon from '../img/vector.svg';
import cartIcon from '../img/Cart.svg';
import ordersIcon from '../img/Cube.svg';
import userIcon from '../img/gg_profile.svg';

import CartDrawer from './CartDrawer';
import ReactModal from 'react-modal';
import Signup from '../pages/Signup';

// Set the app element for accessibility
ReactModal.setAppElement('#root');

const Navbar = () => {
  const { isLoggedIn, logout } = useAuth();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const { isCartVisible, setCartVisible } = useCart();
  const { setCustomer } = useCustomer();
  const { products } = useProducts();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for toggling mobile menu
  const [isSeachVisible, setSearchIsVisible] = useState(false); // Initial state is hidden
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isSearchPopupVisible, setIsSearchPopupVisible] = useState(false); // State for search popup visibility
  const [isSignupModalVisible, setIsSignupModalVisible] = useState(false); // State for signup modal visibility
  const isActive = (path) => (location.pathname === path ? 'active' : '');
  const noBannerPaths = [
    '/product/',
    '/signup',
    '/contact',
    '/products',
    '/orders',
  ];
  const isNonBannerPage = noBannerPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  const handleLogout = () => {
    logout();
    setCustomer(null);
    setShowLogoutDialog(false);
    navigate('/');
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsScrolled(!(searchQuery.length === 0));
  }, [searchQuery]);

  const handleSearch = (event) => {
    const query = event.target.value.trim(); // Trim whitespace
    setSearchQuery(query);

    if (query && typeof products === 'object') {
      const productArray = Object.values(products); // Convert object to array
      const results = productArray
        .filter((product) => {
          // Ensure product has name and category properties
          const matchesName = product.name
            ?.toLowerCase()
            .includes(query.toLowerCase());
          const matchesCategory = product.category
            ?.toLowerCase()
            .includes(query.toLowerCase());
          return matchesName || matchesCategory;
        })
        .slice(0, 3);

      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const handleResultClick = (id) => {
    navigate(`/product/${id}`);
    setSearchResults([]);
    setSearchQuery('');
    setIsSearchPopupVisible(false);
  };

  const handleMenuButtonClick = (event) => {
    event.stopPropagation(); // Prevent the click from propagating
    setIsMenuOpen(!isMenuOpen);
    setIsScrolled(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen) {
        const isNavLink = event.target.closest('.nav-link');
        if (!isNavLink || isNavLink) {
          setIsMenuOpen(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  const renderNavLink = (path, label) => (
    <Link
      className={`nav-link cursor-pointer text-lightWhite px-4 text-[16px] lg:text-[18px] font-semibold ${isActive(path) ? 'text-secondary' : ''}`}
      aria-current={location.pathname === path ? 'page' : undefined}
      to={path}
    >
      {label}
    </Link>
  );

  return (
    <>
      <nav
        className={`navbar ${isScrolled ? 'scrolled' : ''} ${isNonBannerPage ? 'nonbanner' : ''}`}
      >
        {/* Menu Button for Mobile */}
        <div className="container flex justify-between items-center mx-auto p-4">
          <button className="menu-button" onClick={handleMenuButtonClick}>
            <FaBars size={24} />
          </button>

          {/* Logo Section */}
          <Link to="/" className={`navbar-logo`}>
            <img src={logo} alt="Logo" className="navbar-logo-img" />
            <h1 className="navbar-logo-title text-lg ml-3 text-white hidden lg:block">
              <span className="navbar-setia-text">Setia&#39;s</span>
              <span className="navbar-naroi-text">&nbsp;Naroi&nbsp;</span>
              <span className="navbar-sehat-text">Sehat</span>
            </h1>
          </Link>

          {/* Main Navigation Links (Collapsible) */}
          <div className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
            {renderNavLink('/', 'Home')}
            {renderNavLink('/about', 'About us')}
            {renderNavLink('/products', 'Products')}
            {renderNavLink('/contact', 'Contact us')}
            <Link
              to={isLoggedIn ? '/orders' : '#'}
              className="icon-button flex lg:hidden gap-2 items-center icon-button px-4 nav-link text-lightWhite text-[16px] lg:text-[18px] font-semibold"
              onClick={() => {
                if (!isLoggedIn) {
                  setIsSignupModalVisible(true); // Open signup modal if not logged in
                }
              }}
            >
              <span>Orders</span>
            </Link>
            {isLoggedIn ? (
              <div className="relative flex lg:hidden px-4 nav-link text-lightWhite text-[16px] lg:text-[18px] font-semibold">
                <button
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent click from closing the menu
                    setShowLogoutDialog(!showLogoutDialog);
                  }}
                  className="logout-container flex gap-2 items-center"
                >
                  <span className="text-base text-white">Logout</span>
                </button>
                {showLogoutDialog && (
                  <div className="logout-dialog z-10" onClick={handleLogout}>
                    Logout
                  </div>
                )}
              </div>
            ) : (
              <>
                <button
                  className="icon-button flex lg:hidden px-4 nav-link text-lightWhite text-[16px] lg:text-[18px] font-semibold"
                  onClick={() => setIsSignupModalVisible(true)} // Toggle signup modal visibility
                >
                  <img
                    src={userIcon}
                    alt="User"
                    className="w-7 h-7 hidden lg:flex"
                  />
                  <span>Sign In</span>
                </button>

                <ReactModal
                  isOpen={isSignupModalVisible}
                  onRequestClose={() => setIsSignupModalVisible(false)}
                  contentLabel="Sign Up"
                  className="rounded-lg shadow-lg max-w-xl w-full mx-auto relative" // Add relative positioning
                  overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center"
                >
                  <button
                    className="absolute top-1 right:6 lg:right-4 text-gray-500 hover:text-gray-700 text-xl md:text-4xl"
                    onClick={() => setIsSignupModalVisible(false)}
                  >
                    &times; {/* Close icon */}
                  </button>
                  <Signup />
                </ReactModal>
              </>
            )}

            {/* Search, Cart, and Orders for Mobile */}
          </div>

          {/* Search Form & Navbar Icons (Outside Collapse) */}
          <ReactModal
            isOpen={isSearchPopupVisible}
            onRequestClose={() => setIsSearchPopupVisible(false)}
            contentLabel="Search"
            className="rounded-lg shadow-lg max-w-md w-full mx-auto"
            overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-start pt-[100px] md:pt-[150px]"
          >
            <div className="navbar-search relative flex-col mx-4 md:mx-0">
              <input
                className="navbar-search-input w-full p-2 md:p-4 border border-gray-300 rounded text-sm md:text-base"
                type="search"
                placeholder="Search here..."
                value={searchQuery}
                onChange={handleSearch}
                required
              />
              {/* Search Results */}
              <div className="bg-white w-full mt-1 z-10">
                {searchResults.length > 0 &&
                  searchResults.map((product) => (
                    <div
                      key={product.id}
                      className="search-result-item p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleResultClick(product.id)}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_MEDIA_URL}${product.variants[0].images[0].image}`}
                        alt={product.name}
                        className="w-10 h-10 inline-block mr-2"
                      />
                      <span>{product.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          </ReactModal>
          <div className="navbar-icons">
            <button
              className="icon-button flex gap-2 items-center"
              onClick={() => setIsSearchPopupVisible(!isSearchPopupVisible)} // Toggle search popup visibility
            >
              <img src={searchIcon} alt="Search" className="w-5 h-5" />
              <span className="hidden lg:flex">Search</span>
            </button>
            <button
              className="flex icon-button gap-2 items-center"
              onClick={() => setCartVisible(!isCartVisible)}
            >
              <img
                src={cartIcon}
                alt="Cart"
                className="w-5 h-5 md:w-7 md:h-7"
              />
              <span className="hidden lg:flex">Cart</span>
            </button>
            <Link
              to={isLoggedIn ? '/orders' : '#'}
              className="icon-button hidden lg:flex gap-2 items-center"
              onClick={() => {
                if (!isLoggedIn) {
                  setIsSignupModalVisible(true); // Open signup modal if not logged in
                }
              }}
            >
              <img
                src={ordersIcon}
                alt="Orders"
                className="w-5 h-5 md:w-7 md:h-7"
              />
              <span className="hidden lg:flex">Orders</span>
            </Link>
            {isLoggedIn ? (
              <div className="relative hidden lg:flex">
                <button
                  onClick={() => setShowLogoutDialog(!showLogoutDialog)}
                  className="logout-container flex gap-2 items-center"
                >
                  <FaSignOutAlt
                    className="icon-button flex gap-2 items-center"
                    size={24}
                  />
                  <span className="text-base text-white">Logout</span>
                </button>
                {showLogoutDialog && (
                  <div className="logout-dialog z-10" onClick={handleLogout}>
                    Logout
                  </div>
                )}
              </div>
            ) : (
              <>
                <button
                  className="icon-button hidden lg:flex gap-2 items-center"
                  onClick={() => setIsSignupModalVisible(true)} // Toggle signup modal visibility
                >
                  <img src={userIcon} alt="User" className="w-7 h-7" />
                  <span className="hidden lg:flex">Sign In</span>
                </button>

                <ReactModal
                  isOpen={isSignupModalVisible}
                  onRequestClose={() => setIsSignupModalVisible(false)}
                  contentLabel="Sign Up"
                  className="rounded-lg shadow-lg max-w-xl w-full mx-auto relative" // Add relative positioning
                  overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center"
                >
                  <button
                    className="absolute top-1 right-6 lg:right-4 text-gray-500 hover:text-gray-700 text-xl md:text-4xl"
                    onClick={() => setIsSignupModalVisible(false)}
                  >
                    &times; {/* Close icon */}
                  </button>
                  <Signup />
                </ReactModal>
              </>
            )}
          </div>
        </div>
      </nav>
      <CartDrawer show={isCartVisible} />
    </>
  );
};

export default Navbar;
