import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [loading, setLoading] = useState({ products: true, featured: true });
  const [error, setError] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchData = async (url, setData, key) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
      if (!Array.isArray(data)) {
        throw new Error(`Expected an array but received: ${typeof data}`);
      }
      setData(data);
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      setError(error.message);
    } finally {
      setLoading((prev) => ({ ...prev, [key]: false }));
    }
  };

  const refreshProducts = async () => {
    await fetchData(`${apiBaseUrl}/products/`, setProducts, 'products');
    await fetchData(
      `${apiBaseUrl}/products/?status=featured`,
      setFeaturedProducts,
      'featured'
    );
  };

  useEffect(() => {
    refreshProducts();
  }, [apiBaseUrl]);

  const getProductById = (id) => {
    return products.find((product) => product.id === id);
  };

  const getProductDetails = async (id) => {
    try {
      const response = await fetch(`${apiBaseUrl}/products/?product_id=${id}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch product: ${response.statusText}`);
      }
      return await response.json()
    } catch (error) {
      console.error("Error fetching product:", error);
      return null;
    }
  };  

  const generateHash = (productId, variantId) => {
    return (
      ((productId + variantId) * (productId + variantId + 1)) / 2 + variantId
    );
  };

  const retrieveIdsFromHash = (hash) => {
    const w = Math.floor((Math.sqrt(8 * hash + 1) - 1) / 2);
    const t = (w * (w + 1)) / 2;
    const variantId = hash - t;
    const productId = w - variantId;
    return { productId, variantId };
  };

  const clearError = () => setError(null);

  const contextValue = useMemo(
    () => ({
      products,
      featuredProducts,
      loading,
      error,
      getProductById,
      getProductDetails,
      generateHash,
      retrieveIdsFromHash,
      refreshProducts,
      clearError,
    }),
    [products, featuredProducts, loading, error]
  );

  return (
    <ProductContext.Provider value={contextValue}>{children}</ProductContext.Provider>
  );
};

export const useProducts = () => useContext(ProductContext);

ProductProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
