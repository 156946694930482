import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

import { useAuth } from './AuthContext';
import { useCustomer } from './CustomerContext';
import { useProducts } from './ProductContext';

const CartContext = createContext();
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [isCartVisible, setCartVisible] = useState(false);
  const { retrieveIdsFromHash, generateHash, products } = useProducts();
  const { isLoggedIn } = useAuth();
  const { customer } = useCustomer();
  const [cart, setCart] = useState(new Map());

  useEffect(() => {
    const initializeCart = async () => {
      const localCart = getLocalCart();
      let finalCart = localCart;

      if (isLoggedIn) {
        if (localCart.size > 0) {
          await migrateCartToServer(localCart);
        } else {
          finalCart = await fetchCartFromServer();
        }
      }

      updateCartState(finalCart);
    };

    initializeCart();
  }, []);

  const getLocalCart = () => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? new Map(JSON.parse(storedCart)) : new Map();
  };

  // Migrate local cart to server
  const migrateCartToServer = async (localCart) => {
    if (localCart.size > 0) {
      try {
        await fetch(`${API_BASE_URL}/cart/`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customer_id: customer.id,
            products: Object.fromEntries(localCart),
          }),
        });
      } catch (error) {
        console.error('Error migrating cart:', error);
      }
    }
  };

  const fetchCartFromServer = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/cart/`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      if (data.success) {
        return new Map(Object.entries(data.cart.products));
      }
    } catch (error) {
      console.error('Error fetching cart:', error);
    }
    return new Map();
  };

  const updateCartState = useCallback((newCart) => {
    setCart(newCart);
    setCartItems(Array.from(newCart.keys()));
    localStorage.setItem('cart', JSON.stringify(Array.from(newCart.entries())));
  }, []);

  const addToCart = async (hash, quantity = 1) => {
    const { productId, variantId } = retrieveIdsFromHash(hash);
    const newCart = new Map(cart);

    if (isLoggedIn) {
      console.log(isLoggedIn);

      try {
        await fetch(`${API_BASE_URL}/cart/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            product_id: productId,
            variant_id: variantId,
            quantity,
            customer_id: customer.id,
          }),
        });
      } catch (error) {
        console.error('Error adding to cart:', error);
        return;
      }
    }

    const existingItem = newCart.get(hash);

    if (existingItem) {
      newCart.set(hash, { 
        ...existingItem, 
        quantity: existingItem.quantity + quantity 
      });
    } else {
      const product = products.find(p => p.id === productId && p.variant_id === variantId);
    
      if (product) {
        const newItem = {
          hash_value: hash,
          name: product.name,
          price: product.price,
          mrp: product.mrp,
          weight: product.weight,
          discount: product.discount,
          delivery_charge: product.delivery_charge,
          image: product.image,
          quantity: quantity,
        };
        
        newCart.set(hash, newItem);
      } else {
        console.error('Product not found in the products list.');
      }
    }
    
    updateCartState(newCart);
  };

  const removeFromCart = async (hash, quantity = 1) => {
    const { productId, variantId } = retrieveIdsFromHash(hash);
    const newCart = new Map(cart);

    if (isLoggedIn) {
      try {
        await fetch(`${API_BASE_URL}/cart/`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            product_id: productId,
            variant_id: variantId,
            quantity,
            customer_id: customer.id,
          }),
        });
      } catch (error) {
        console.error('Error removing from cart:', error);
        return;
      }
    }

    const existingItem = newCart.get(hash);
    if (existingItem?.quantity > quantity) {
      newCart.set(hash, { ...existingItem, quantity: existingItem.quantity - quantity });
    } else {
      newCart.delete(hash);
    }

    updateCartState(newCart);
  };

  const isInCart = (hash) => cart.has(hash);

  const clearCart = () => {
    setCart(new Map());
    setCartItems([]);
    localStorage.removeItem('cart');
  };

  const getQuantity = (hash) => cart.get(hash)?.quantity || 0;

  return (
    <CartContext.Provider
      value={{
        cart,
        cartItems,
        isCartVisible,
        getQuantity,
        addToCart,
        removeFromCart,
        clearCart,
        isInCart,
        setCartVisible,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
